<template>
    <div
        class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 px-4 sm:gap-x-6 sm:px-6 lg:px-8"
        :class="{'shadow-sm' : !layoutStore.isSubheaderOpen}">
        <button
            type="button"
            class="-m-2.5 p-2.5 text-gray-700 dark:text-gray-300 lg:hidden"
            @click="layoutStore.isSidebarOpen = true">
            <span class="sr-only">Open sidebar</span>
            <Icon
                name="heroicons:bars-3"
                class="h-6 w-6"
                aria-hidden="true" />
        </button>

        <!-- Separator -->
        <div
            class="h-6 w-px bg-gray-200 lg:hidden"
            aria-hidden="true" />

        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <div class="flex flex-1 items-center">
                <GlobalSearch v-if="useGlobalSearch" />
            </div>

            <div class="flex items-center gap-x-4 lg:gap-x-6">
                <!--                <BaseSlotButton-->
                <!--                    :title="$t('notifications.title')"-->
                <!--                    type="button"-->
                <!--                    class="-m-2.5 p-2.5 text-gray-400 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-200 relative"-->
                <!--                    @click="inboxOpen = !inboxOpen">-->
                <!--                    <span class="sr-only">View notifications</span>-->
                <!--                    <Icon-->
                <!--                        name="heroicons:bell"-->
                <!--                        class="h-6 w-6"-->
                <!--                        aria-hidden="true" />-->
                <!--                    <span-->
                <!--                        v-if="notificationStore.unreadNotificationsCount > 0"-->
                <!--                        class="w-2 h-2 bg-danger-500 block absolute top-3 right-3 rounded-full" />-->
                <!--                    <NotificationInbox v-model="inboxOpen" />-->
                <!--                </BaseSlotButton>-->

                <!--                &lt;!&ndash; Separator &ndash;&gt;-->
                <!--                <div-->
                <!--                    class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"-->
                <!--                    aria-hidden="true" />-->

                <UserMenu />
            </div>
        </div>
    </div>
</template>

<script setup>
import NotificationInbox from "@/components/global/NotificationInbox.vue"
import UserMenu from '@/components/layouts/UserMenu/UserMenu'
import {useLayoutStore} from "@/store/layout"
import {useNotificationStore} from "@/store/notifications"

const inboxOpen = ref(false)
const useGlobalSearch = ref(true)

const layoutStore = useLayoutStore()
const notificationStore = useNotificationStore()
</script>
