export const releaseNotesDe: ReleaseNotes = [
    {
        release: "0.6.1",
        date: "2025-03-25",
        tags: [
            "SAML2"
        ],
        type: "bugfix",
        notes: [
            {
                "description": "Anpassungen für SAML2"
            },
        ]
    },
    {
        release: "0.6.0",
        date: "2025-03-18",
        tags: [
            "Meilenstein 6", "Fertigstellung"
        ],
        type: "minor",
        notes: [
            {
                "description": "Abschluss Meilenstein 6"
            },
            {
                "description": "Erstes Dashboard"
            },
            {
                "description": "Server für Produktivbetrieb vorbereitet"
            },
            {
                "description": "Angebotsnummern"
            },
            {
                "description": "Details in Beschaffungen"
            },
            {
                "description": "Anschriften für Lieferanten"
            },
            {
                "description": "Raumnummer Pflicht"
            },
            {
                "description": "Feature Tests"
            },
            {
                "description": "Stornieren und Löschen von Beschaffungen"
            },
            {
                "description": "Details in Vorlagen der PDFs"
            },

        ]
    },
    {
        release: "0.5.0",
        date: "2025-01-31",
        tags: [
            "Meilenstein 5", "Genehmigungen"
        ],
        type: "minor",
        notes: [
            {
                "description": "Abschluss Meilenstein 5"
            },
            {
                "description": "Stornieren von Beschaffungen und Angebotseinholung"
            },
            {
                "description": "Registrierung von Ansprechpersonen"
            },
            {
                "description": "Gesamtkonzept Beschaffung-Workflow"
            },
            {
                "description": "Vollständige Implementierung der Genehmigungen"
            },
            {
                "description": "Erweiterung des Statusbaum"
            },
            {
                "description": "Aufwertung der Beschaffungshistorie"
            },
            {
                "description": "Korrekturverfahren für Beschaffungen"
            },
            {
                "description": "Einbindung aller Benachrichtigungen"
            },
            {
                "description": "Direktlinks für Genehmigungen"
            },
            {
                "description": "Erweiterte Ansicht für Lieferanten"
            }
        ]
    },
    {
        release: "0.4.0",
        date: "2024-12-16",
        tags: [
            "Meilenstein 4", "Lieferanten"
        ],
        type: "minor",
        notes: [
            {
                "description": "Abschluss Meilenstein 4"
            },
            {
                "description": "Entfernung der Standardgeräte"
            },
            {
                "description": "Ansprechpersonen Anmeldung und Verwaltung"
            },
            {
                "description": "Angebotseinholung implementiert"
            },
            {
                "description": "Erste Benachrichtigungen implementiert"
            },
            {
                "description": "Anlegen von Angeboten als Ansprechperson oder Beschaffer"
            },
            {
                "description": "Auswahl von Angeboten für die Beschaffung"
            }
        ]
    },
    {
        release: "0.3.0",
        date: "2024-11-01",
        tags: [
            "Meilenstein 3", "Beschaffungen"
        ],
        type: "minor",
        notes: [
            {
                "description": "Abschluss Meilenstein 3"
            },
            {
                "description": "Grundmodell der Beschaffungen implementiert"
            },
            {
                "description": "Definition der Beschaffungsarten konzipiert"
            },
            {
                "description": "Beschaffungshistorie vorbereitet"
            },
            {
                "description": "Auswahlmöglichkeiten für Kostenstellen, Verantwortliche und Positionen implementiert"
            },
            {
                "description": "PDF Exporte vorbereitet"
            },

        ]
    },
    {
        release: "0.2.0",
        date: "2024-09-12",
        tags: [
            "Meilenstein 2", "Stammdaten"
        ],
        type: "minor",
        notes: [
            {
                "description": "Abschluss Meilenstein 2"
            },
            {
                "description": "Nutzerverwaltung erweitert"
            },
            {
                "description": "Kategorien implementiert"
            },
            {
                "description": "Kostenstellen implementiert"
            },
            {
                "description": "Standardgeräte implementiert"
            },
            {
                "description": "Mailvorlagen vorbereitet"
            }
        ]
    },
    {
        release: "0.1.0",
        date: "2024-07-31",
        tags: [
            "Meilienstein 1", "Grundsystem"
        ],
        type: "minor",
        notes: [
            {
                "description": "Abschluss Meilenstein 1"
            },
            {
                "description": "Fertigstellung des Grundsystems"
            },
            {
                "description": "Nutzerverwaltung implementiert"
            },
            {
                "description": "Berechtigungssystem vorbereitet"
            }
        ]
    },
    {
        release: "0.0.0",
        date: "2024-07-01",
        tags: [
            "Projektstart"
        ],
        type: "major",
        notes: [
            {
                "description": "Das Projekt wurde aufgesetzt. Die Entwicklung kann beginnen!"
            }
        ]
    }
]