import {$getModuleIcon} from "./appModules"

export const sidebarMenu: SidebarMenu = [
    {
        title: 'default',
        menu: [
            {
                title: 'menu.dashboard',
                path: '/',
                icon: $getModuleIcon('dashboard'),
                module: 'dashboard',
            },
            {
                title: 'menu.orders',
                path: 'orders',
                icon: $getModuleIcon('orders'),
                module: 'orders',
                ability: ['view', 'orders'],
            },
            {
                title: 'menu.company',
                path: 'company-companyBaseData',
                icon: $getModuleIcon('company'),
                module: 'company',
                ability: ['represent', 'organisations'],
            },
            {
                title: 'menu.tenders',
                path: 'tenders',
                icon: $getModuleIcon('tenders'),
                module: 'tenders',
                ability: ['viewAny', 'tenders'],
            },
            {
                title: 'menu.organisations',
                path: 'organisations',
                ability: ['update', 'organisations'],
                icon: $getModuleIcon('organisations'),
                module: 'organisations',
            },
            {
                title: 'menu.baseData',
                path: 'baseData',
                icon: $getModuleIcon('baseData'),
                module: 'baseData',
                children: [
                    {
                        title: 'menu.costCenters',
                        path: 'baseData-costCenters',
                        icon: $getModuleIcon('baseData'),
                        module: 'baseData',
                        ability: ['update', 'costCenters']
                    },
                    {
                        title: 'menu.mailTemplates',
                        path: 'settings-mailTemplates',
                        icon: $getModuleIcon('baseData'),
                        module: 'baseData',
                        ability: ['update', 'mailTemplates']
                    },
                    {
                        title: 'menu.categories',
                        path: 'baseData-categories',
                        icon: $getModuleIcon('baseData'),
                        module: 'baseData',
                        ability: ['update', 'categories']
                    },
                    {
                        title: 'menu.users',
                        module: 'users',
                        path: 'users',
                        icon: $getModuleIcon('settings'),
                        ability: ['viewAny', 'users']
                    },
                    {
                        title: 'menu.loginProviders',
                        module: 'loginProviders',
                        path: 'settings-loginProviders',
                        ability: ['viewAny', 'users']
                    }
                ]
            }
        ]
    },
]